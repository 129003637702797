//
// = Header Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
header.st_header_area {
  z-index: 1;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0;
  @include transition();
  .st_default_btn {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 50px;
    font-weight: 600;
    font-size: 0.875em;
  }
  @include media-max(MD) {
    padding: 5px 0;
  }
}
.st_header-wrapper {
  header.st_header_area {
    position: absolute;
  }
}

$nav_width : 11rem;
.st_menu{
  ul{
    @include st_ul();
    > li{
      display: inline-block;
      margin: 0 22px;
      text-transform: capitalize;
      position: relative;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      > a:not(.st_default_btn) {
        display: block;
        padding: 1.875em 0;
        @include transition();
        > i {
          position: relative;
          top: 0;
          left: 3px;
          font-size: 0.8em;
          @include media-max(MD) {
            display: none;
          }
        }
      }
      a:not(.st_default_btn) {
        color: $color-title;
        &:hover{
          color: $color-aqua;
        }
      }
      @include media-max(LG) {
        margin: 0 15px;
      }
      ul {
        position: absolute;
        list-style: none;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
        background-color: #fff;
        margin: 0;
        padding: 0;
        width: $nav_width;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        @include transform(translate(0, 10px));
        @include transition();
        li {
          margin: 0;
          display: block;
          float: none;
          width: 100%;
          a {
            color: $color-primary !important;
            padding: 0.375rem 1rem  !important;
            @include font-size(14);
            &:hover {
              color: $color-haiti !important;
            }
          }
          &.st-has-submenu {
            > a {
              &::after {
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f054";
                position: absolute;
                right: 0.625em;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #fff;
                @include media-max(MD) {
                  display: none;
                }
              }
            }
          }
        }
        &::before,
        &::after {
          content: "";
          display: table;
          clear: both;
        }
        &.nav-col-2 {
          width: $nav_width * 2;
          li {
            float: left;
            width: (100% / 2);
          }
        }
        &.nav-col-3 {
          width: $nav_width * 3;
          li {
            float: left;
            width: (100% / 3);
          }
        }
        &.nav-col-4 {
          width: $nav_width * 4;
          li {
            float: left;
            width: (100% / 4);
          }
        }
        ul {
          left: $nav_width;
          top: 0;
        }
      }
      &:hover {
        > ul {
          opacity: 1;
          visibility: visible;
          @include transform(translate(0,0));
        }
      }
    }
  }
  @include media-max(MD) {
    display: none;
  }
  @include media-max(SM) {
    display: none;
  }
}

header.sticky-menu {
  padding: 0;
  background: #5040a0;
  background: -moz-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: -webkit-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: linear-gradient(to right, #5040a0 0%, #8066dc 51%, #5040a0 100%);
  background-size: 200% auto;
  z-index: 999;
  position: fixed !important;
  top: 0;
  .st_menu {
    ul {
      > li {
        > a:not(.st_default_btn) {
          padding: 1.25rem 0;
        }
      }
    }
  }
  @include media-max(MD) {
    padding: 10px 0;
  }
}
header.sticky-menu.sticky-aqua-gradient {
  @extend %aqua-gredient-bgc;
}
header.sticky-menu.sticky-gigas-gradient {
  @extend %gigas-btn-gradient;
}

@each $name, $color in $colors {
  header.st_header_area .st_menu.text-#{$name} ul li a:not(.st_default_btn) {
    color: $color;
  }
  header.sticky-menu.sticky-bg-#{name} {
    background-color: $color;
  }
  header.st_header_area .st_menu.text-hover-#{$name} ul li a:not(.st_default_btn):hover,
  header.st_header_area .st_menu.text-hover-#{$name} ul li a:not(.st_default_btn):focus,
  header.st_header_area .st_menu.text-hover-#{$name} ul li a:not(.st_default_btn):active {
    color: $color;
  }
}
header.st_header_area {
  a.st_default_btn {
    margin: 0;
  }
}

.st_header_top {
  background-color: #24292E;
  padding: 8px 0;
  display: flex;
  align-items: center;
  @include transition();
  .st_header_top_content {
    display: inline-block;
    p {
      margin: 0;
    }
  }
}

.mean-container .mean-bar {
  background-color: transparent;
}
.mean-container .mean-nav {
  position: absolute;
  width: calc(100vw - 30px);
  max-width: 500px;
  right: 0;
  margin-top: 56px;
  background: $color-white;
  box-shadow: 0 0 35px rgba(0,0,0,.08);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  @include media-max(SM) {
    margin-top: 50px;
    top: 0;
    max-height: calc(100vh - 55px);
    overflow: auto;
  }
}
.mean-container .mean-nav ul li a {
  border-top: 1px solid rgba(0,0,0,0.05);
  color: #212529;
  text-transform: capitalize;
  &.st_default_btn.st_btn_round {
    border-radius: 0px !important;
  }
}

.st_header-wrapper {
  position: relative;
  z-index: 999;
}

.mean-container {
  &.st_nav_wrapper_v2 {
    @include media-max(MD) {
      .mean-bar {
        right: 40px;
        .mean-nav {
          right: -40px;
        }
      }
      .st_menu_sign {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        right: 20px;
      }
    }
  }
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background-color: transparent;
}
@include media-max(MD) {
  .mean-nav > ul > li > a > i {
    display: none;
  }
  .st-has-submenu {
    i {
      display: none;
    }
  }
}
//
// = Client Slider styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_client_slider.owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}
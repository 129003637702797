//
// = Tab Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_custom-tab {
  @extend %aqua-tab-gradient;
  background-size: 200% auto;
  box-shadow: 0 11px 30px rgba(28, 76, 223, 0.36);
  border-radius: 3px;
  position: relative;
  ul.nav.nav-pills {
    @include st_ul();
    flex-wrap: inherit;
    overflow: hidden;
    li {
      width: 100%;
      a {
        color: $color-white;
        font-size: 1.125em;
        height: 100%;
        display: block;
        text-align: center;
        @include transition();
        padding: 50px 30px;
        border-radius: 0;
        span {
          margin-left: 16px;
          @include media-max(LG) {
            display: block;
            text-align: center;
            margin-top: 18px;
          }
          @include media-max(MD) {
            display: none;
          }
        }
        img {
          @include media-max(LG) {
            display: inline-block;
          }
        }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include media-max(LG) {
          padding: 35px 20px;
        }
        @include media-max(LG) {
          padding: 35px 5px;
        }
      }
    }
  }
}
.st_tab_box {
  background-color: $color-white;
  padding: 75px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 50px rgba($color-black, 0.02);
  @include media-max(MD) {
    padding: 55px 50px;
    display: block;
  }
  @include media-max(SM) {
    padding: 35px 30px;
    display: block;
  }
  .st_tab_single_img {
    flex: 0 0 50%;
    margin-right: 56px;
    @include media-max(MD) {
      margin-right: 0;
      margin-bottom: 50px;
      text-align: center;
    }
  }
  .st_tab_content {
    h3 {
      font-size: 1.5em;
      margin: 0 0 33px
    }
    p {
      line-height: 2;
    }
  }
}
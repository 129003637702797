//
// = Info Box styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_info_box{
	display: flex;
	padding: 60px 40px;
	border-radius: 5px;
	.st_info_box_icon{
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 0 auto;
		margin-right: 40px;
	}
	.st_info_box_content{
		h3{
			font-size: 1.5em;
			margin-bottom: 25px;
			font-weight: 400;
		}
		p{
			@include font-size(14);
			line-height: 2;

		}
	}
	@include media-max(SM) {
		display: block;
		padding: 50px 15px 40px;
		text-align: center;
		.st_info_box_icon{
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
}
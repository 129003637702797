//
// = Testimonial Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_testimonial_item {
  margin: 0 0 90px;
  position: relative;
  .st_testimonial_content {
	  background-color: #5040A0;
	  width: 54%;
	  margin: 0 auto;
	  border-radius: 5px;
	  padding: 50px 67px 80px;
	  z-index: 0;
	  @include media-max(MD) {
			width: 80%;
			padding: 50px 40px 80px;
	  }
	  @include media-max(MD) {
			width: 100%;
	  }
	  &::before,
	  &::after {
			width: 57%;
		  height: 91%;
		  position: absolute;
		  left: 50%;
		  top: 50%;
		  content: "";
		  background-color: rgba($color-black, 0.04);
		  transform: translate(-50%, -50%);
		  border-radius: 3px;
		  z-index: -1;
		  @include media-max(MD) {
				width: 84%;
		  }
	  }
	  &::after {
	  	width: 59%;
  		height: 82%;
  		@include media-max(MD) {
				width: 88%;
		  }
	  }
	  h3 {
	  	font-size: 1.500em;
		  color: #FFE600;
		  font-weight: 400;
		  margin: 0 0 30px;
	  }
	  p {
			color: #D7D2F1;
			font-weight: 300;
			line-height: 2.1;
	  }
	}
	.st_testimonial_client {
	  position: absolute;
	  bottom: -90px;
	  left: 50%;
	  transform: translateX(-50%);
	  .client_img {
		  width: 70px !important;
		  height: 70px;
		  border-radius: 100%;
		  margin: 0 auto 10px;
		  overflow: hidden;
		  img {
	  	  width: 100%;
			  height: 100%;
			  object-fit: cover;
		  }
		}
	}
	.client_title {
		h4 {
			color: $color-white;
			font-size: 0.875em;
			font-weight: 700;
			margin: 0 0 10px;
			text-transform: capitalize;
		}
		p {
			color: #C4C4C4;
			font-size: .75em;
			margin: 0;
			text-transform: uppercase;
		}
	}

}
.owl-carousel.owl-border-nav {
	.owl-nav {
		@include media-max(MD) {
			text-align: center;
		}
	}
}
.owl-carousel.owl-border-nav .owl-nav .owl-prev,
.owl-carousel.owl-border-nav .owl-nav .owl-next {
	background-color: transparent;
	color: $color-white;
	font-size: 1em;
	height: 44px;
	width: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: 2px solid #CCC2FF;
	position: absolute;
	opacity: 0.4;
	left: 120px;
	top: 35%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	border-radius: 100%;
	z-index: 1;
	&:hover {
		opacity: 1;
	}
	@include media-max(MD) {
		left: 0;
	}
	@include media-max(MD) {
		left: inherit;
		top: inherit;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		position: relative;
		margin: 50px 15px 0;
		display: inline-block;
	}
}
.owl-carousel.owl-border-nav .owl-nav .owl-next{
	left: auto;
	right: 120px;
	@include media-max(MD) {
		right: 0;
	}
}

.st_v2_testimonial_item {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05);
  padding: 45px 40px;
  margin: 10px 15px 35px;
  @include media-max(SM) {
	  padding: 35px 34px;
	  box-shadow: none;
	  border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .st_testimonial_content {
		margin-bottom: 40px;
		position: relative;
		&:before {
			content: "\f10e";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color: #F3F2F2;
			font-size: 3em;
			margin-right: 10px;
			position: absolute;
			right: 0;
			bottom: -30px;
			z-index: -1;
			@include media-max(SM) {
				display: none;
			}
		}
		h3 {
			margin: 0 0 20px;
		}
		p {
			line-height: 2;
		}
		@include media-max(SM) {
			p {
				line-height: 1.8;
				word-break: break-all;
			}
		}
  }
  .st_testimonial_feedback {
	  display: flex;
	  justify-content: space-between;
	  @include media-max(SM) {
		  display: block;
	  }
	  .st_client_waraper {
		  display: flex;
		  .st_client_img {
			  width: 50px;
			  border-radius: 100%;
			  overflow: hidden;
			  height: 50px;
			  margin-right: 20px;
			  img {
					width: 100%;
					height: 100%;
					object-fit: cover;
			  }
			}
			.st_client_name {
			  display: flex;
			  flex-direction: column;
			  justify-content: center;
			  @include media-max(SM) {
					display: block;
					justify-content: left;
			  }
			  h4 {
		  	  font-size: 0.875em;
				  font-weight: 700;
				  text-transform: capitalize;
				  margin: 0 0 12px;
			  }
			  p {
					font-size: .75em;
					text-transform: uppercase;
					line-height: 1;
			  }
			}
		}
		.st_client_review {
		  display: flex;
		  align-items: center;
		  ul {
		  	@include st_ul();
		  	li {
					display: inline-block;
					margin: 0 3px;
					text-transform: capitalize;
					a {
						color: #FFB300;
						font-size: 0.875em;
					}
		  	}
		  	@include media-max(SM) {
				  width: auto;
			  }
		  }
		  @include media-max(SM) {
		  	margin-left: 70px;
		  	ul {
		  		li {
		  			margin: 0;
		  			a {
		  				@include font-size(10);
		  			}
		  		}
		  	}
		  }
		}
	}
}
.st_slider_bottom_dots .owl-dots {
  text-align: center;
}
.st_slider_bottom_dots.owl-carousel .owl-dot {
  border: 1px solid #B7B7B7;
  display: inline-block;
  height: 11px;
  margin-right: 10px;
  width: 11px;
  margin-top: 30px;
  border-radius: 10px;
  @include media-max(SM) {
		margin-top: 0;
  }
}
.st_slider_bottom_dots.owl-carousel .owl-dot.active {
  background: $color-aqua none repeat scroll 0 0;
  height: 15px;
  width: 15px;
  border: 2px solid #FFF3F4;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}
.st_testimonial_slider_v2 {
	.st_testimonial_item {
		.st_testimonial_content {
			text-align: center;
			&::before,
			&::after {
				background-color: rgba(255, 255, 255, 0.04);
			}
			background-color: $color-white;
			h3 {
				color: $color-title
			}
			p {
				color: $color-primary;
			}
		}
		.st_testimonial_client {
			.client_img {
				width: 90px !important;
				height: 90px;
				box-shadow: 0 16px 20px rgba(20, 15, 48, 0.32);
			}
			.client_title {
				h4 {
					color: $color-title
				}
			}
		}
	}
}
.st_testimonial_slider_v2.owl-carousel.owl-border-nav {
	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		color: #CCC2FF;
	  	opacity: .4;
	  	&:hover{
	  		opacity: 1;
	  	}
	}
}
.st_testimonial_slider_v3 {
	.st_testimonial_item {
		margin: 0;
		padding: 80px 0 0;
		.st_testimonial_client {
		  position: static;
		  transform: none;
		  margin-bottom: 40px;
		  .client_img {
			  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
			}
		}
		.st_testimonial_content {
		  background-color: transparent;
		  width: 59%;
		  padding: 0;
		  &::before,
		  &::after {
		  	background-color: rgba(255, 255, 255, 0.04);
		  	opacity: 0;
		  }
		  p {
				color: $color-primary;
				font-size: 1.5em;
				margin-bottom: 55px;
				font-family: $font-title;
				font-weight: 300;
				@include media-max(SM) {
					@include font-size(16);
				}
		  }
		  h3 {
				color: $color-title;
				font-size: 1.125em;
				font-weight: bold;
				margin: 0;
				span {
					font-weight: 300;
				}
		  }
		}
		.st_quote {
		  position: absolute;
		  top: 30px;
		  z-index: -1;
		  left: 70px;
		}
	}
	@include media-max(MD) {
		.st_testimonial_item {
			.st_testimonial_content {
				width: 100%;
			}
		}
	}
}

.st_testimonial_slider_v3.owl-carousel .owl-dot {
	background: #AAFFC5;
	border-radius: 10px;
	display: inline-block;
	height: 11px;
	margin-right: 11px;
	width: 11px;
	margin-top: 60px;
}
.st_testimonial_slider_v3.owl-carousel .owl-dot.active {
	background: $color-emerald none repeat scroll 0 0;
	height: 14px;
	width: 14px;
	border: 2px solid #fff;
	position: relative;
	top: -1px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}
.owl-carousel .owl-dots {
  text-align: center;
}

.st_online_community_item{
	.st_single_item{
		animation-name: pulse_animation;
		animation-duration: 5000ms;
		transform-origin:70% 70%;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		position: absolute;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
		border-radius: 100%;
		overflow: hidden;
		z-index: 1;
		opacity: 0.6;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&.item_1{
			width: 121px;
			height: 121px;
			left: -25px;
			bottom: 20px;
		}
		&.item_2{
			width: 69px;
			height: 69px;
			left: 23%;
			bottom: 35%;
		}
		&.item_3, &.item_4, &.item_5{
			width: 37px;
			height: 37px;
			left: 6%;
			top: 50%;
		}
		&.item_4{
			left: 22%;
			top: 32%;
		}
		&.item_5{
			left: auto;
			top: auto;
			right: 20%;
			bottom: 10%;
		}
		&.item_6{
			width: 57px;
			height: 57px;
			right: 8%;
			bottom: 27%;
		}
		&.item_7{
			width: 71px;
			height: 71px;
			right: 18%;
			top: 40%;
		}
		&.item_8, &.item_9{
			width: 83px;
			height: 83px;
			right: 28%;
			top: 13%;
		}
		&.item_9{
			right: -30px;
			top: 8%;
		}
		&.item_10{
			width: 71px;
			height: 71px;
			right: 12%;
			top: 30%;
		}
		&.item_11{
			width: 85px;
			height: 85px;
			right: 3%;
			top: 50%;
		}
		&.item_12{
			width: 67px;
			height: 67px;
			right: 13%;
			bottom: 20%;
		}
	}
	&.st_online_community_item_v2 {
		.st_single_item{
			&.item_1{
				width: 121px;
				height: 121px;
				left: 0;
				bottom: 27%;
			}
			&.item_2 {
				width: 89px;
				height: 89px;
				left: 28%;
				bottom: 23%;
			}
			&.item_2 {
				width: 89px;
				height: 89px;
				left: 28%;
				bottom: 23%;
				@include media-max(LG) {
					width: 50px;
					height: 50px;
					left: 28%;
					bottom: 17%;
				}
			}
			&.item_3{
				width: 70px;
				height: 70px;
				left: 6%;
				top: 24%;
			}
			&.item_4{
				width: 70px;
				height: 70px;
				left: 20%;
				top: 18%;
			}
			&.item_5{
				width: 89px;
				height: 89px;
				left: 32%;
				top: 25%;
				@include media-max(LG) {
					left: 32%;
					top: 5%;
					width: 60px;
					height: 60px;
				}
			}
			&.item_6{
				width: 69px;
				height: 69px;
				left: 17%;
				bottom: 45%;
				right: auto;
			}
			&.item_7{
				width: 101px;
				height: 101px;
				right: 24%;
				top: 50%;
				@include media-max(LG) {
					width: 70px;
					height: 70px;
				}
			}
			&.item_8{
				width: 83px;
				height: 83px;
				right: 28%;
				top: 13%;
				@include media-max(LG) {
					width: 50px;
					height: 50px;
				}
			}
			&.item_9{
				width: 83px;
				height: 83px;
				right: -30px;
				top: 8%;
			}
			&.item_10{
				width: 71px;
				height: 71px;
				right: 12%;
				top: 30%;
			}
			&.item_11{
				width: 85px;
				height: 85px;
				right: 3%;
				top: 50%;
			}
			&.item_12{
				width: 67px;
				height: 67px;
				right: 13%;
				bottom: 20%;
			}
			@include media-max(MD) {
				display: none;
			}
		}
	}
	&.st_online_community_item_v3 {
		.st_single_item{
			opacity: .09;
			&.item_1{
				bottom: 115px;
			}
			&.item_2, &.item_3, &.item_4, &.item_5{
				width: 69px;
				height: 69px;
			    left: 4%;
			    top: 30%;
			}
			&.item_3 {
				left: 16%;
				top: 7%;
			}
			&.item_4{
				left: 15%;
				bottom: 27%;
				top: auto;
			}
			&.item_5{
				left: 13%;
				top: auto;
				bottom: 3%;
			}
			&.item_6, &.item_7{
				width: 89px;
				height: 89px;
				left: 32%;
				top: 13%;
			}
			&.item_7{
				left: 33%;
				top: 62%;
			}
			&.item_8, &.item_9, &.item_13{
				right: -20px;
				top: 8%;
				width: 83px;
				height: 83px;
			}
			&.item_9{
				right: -20px;
				bottom: 6%;
				top: auto;
			}
			&.item_10{
				right: 90px;
				top: 45%;
				width: 85px;
				height: 85px;
			}
			&.item_11{
				right: 15%;
				top: 27%;
				width: 71px;
				height: 71px;
			}
			&.item_12{
				right: 15%;
				bottom: 25%;
				width: 67px;
				height: 67px;
			}
			&.item_13{
				right: 30%;
				top: 10%
			}
			&.item_14{
				right: 32%;
				bottom: 12%;
				width: 101px;
				height: 101px;
			}
		}
	}
	&.st_online_community_item_v4{
		.st_single_item{
			&.item_1{
				width: 121px;
				height: 121px;
				left: -25px;
				bottom: 20px;
				@include media-max(MD) {
					width: 70px;
					height: 70px;
				}
			}
			&.item_2{
				width: 69px;
				height: 69px;
				left: 23%;
				bottom: 35%;
				@include media-max(LG) {
					left: 13%;
				}
				@include media-max(MD) {
					display: none;
				}
			}
			&.item_3, &.item_4, &.item_5{
				width: 37px;
				height: 37px;
				left: 6%;
				top: 50%;
			}
			&.item_4{
				left: 22%;
				top: 32%;
			}
			&.item_5{
				left: auto;
				top: auto;
				right: 20%;
				bottom: 10%;
			}
			&.item_6{
				width: 57px;
				height: 57px;
				right: 8%;
				bottom: 27%;
				@include media-max(MD) {
					display: none;
				}
			}
			&.item_7{
				width: 71px;
				height: 71px;
				right: 18%;
				top: 40%;
			}
			&.item_8{
				width: 83px;
				height: 83px;
				right: -13px;
				top: 23%;
			}
			@include media-max(SM) {
				display: none;
			}
		}
	}
}

// sasspay
.st_v4_testimonial_slider {
	.st_testimonial_waraper {
	  display: flex;
	  padding: 100px 0 55px;
	  @include media-max(MD) {
		display: block;
	  }
	  @include media-max(SM) {
		padding: 30px 0 0;
	  }
	  .st_testimonial_left{
			position: relative;
			margin-left: 40px;
			height: auto;
			flex: 0 0 40%;
			margin-right: 100px;
			border-radius: 100%;
			box-shadow: 0 3px 50px rgba(0, 0, 0, 0.41);
			z-index: 0;
			&:before{
				background-color: $color-lochmara;
				width: 456px;
				height: 456px;
				position: absolute;
				left: -40px;
				top: -40px;
				content: "";
				border-radius: 100%;
				z-index: -1;
			}
			&:after{
				content: "\f10d";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				top: -40px;
				right: -7px;
				font-size: 7.5em;
				color: $color-lochmara;
				line-height: 1;
			}
			@include media-max(LG) {
				width: 360px;
				height: 360px;
				&::before {
					width: 100%;
					height: 100%;
					top: -61px;
				}
				&::after {
					top: -17px;
					font-size: 5.5em;
				}
			}
			@include media-max(MD) {
				width: 290px;
				height: 290px;
				margin: 0 auto;
				&::before {
					top: -32px;
				}
			}
			@include media-max(SM) {
				&::before {
					display: none;
				}
			}
		}
		.st_testimonial_right{
			padding: 60px 0;
			@include media-max(MD) {
				text-align: center;
			}
			@include media-max(SM) {
				padding-bottom: 0;
			}
			.st_testimonial_content{
				position: relative;
				margin-bottom: 50px;
				h3{
					font-size: 1.5em;
					margin-bottom: 20px;
				}
				p{
					line-height: 2;
				}
				&:before{
				content: "\f10e";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				bottom: -120px;
				right: 0;
				font-size: 5.5em;
				color: rgba(20, 15, 48, .04);
				line-height: 1;
				z-index: -1;
				@include media-max(LG) {
					right: 0;
					font-size: 4.5em
				}
			}
			}
			.st_testimonial_client{
				h3{
					color: $color-lochmara;
					margin-bottom: 10px;
					text-transform: capitalize;
				}
			}
		}
	}
	&.st_v4_testimonial_slider_v2 {
		.st_testimonial_waraper {
			padding-top: 0;
			.st_testimonial_right {
				padding-bottom: 85px;
				.st_testimonial_content {
					&::before {
						background-image: url(../images/v18/quote-object.png);
						background-repeat: no-repeat;
						background-size: 100%;
						width: 64px;
						@include media-max(SM) {
							display: none;
						}
					}
				}
			}
		}
	}
}
.st_testimonial_avatar {
  position: absolute;
  bottom: 0;
  left: 60%;
  width: 600px;
}

.owl-carousel.st_v4_testimonial_slider .owl-nav {
	position: absolute;
	left: calc(40% + 140px);
	bottom: 55px;
	@include media-max(MD) {
		left: 50%;
		bottom: 45px;
		@include transform(translateX(-50%));
	}
	@include media-max(SM) {
		left: 0;
		@include transform(translateX(0));
		width: 100%;
		bottom: inherit;
		text-align: center;
		display: none;
	}
} 
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev,
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next {
	@include transition();
	opacity: .4;
	width: 110px;
	border-radius: 0 50px 50px 0;
	padding: 16px 0 !important;
	display: inline-block;
	text-align: center;
	&:hover{
		opacity: 1;
	}
	@include media-max(SM) {
		display: inline-block;
	}
}
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-prev {
	border-radius: 50px 0 0 50px;
	background: -moz-linear-gradient(left, rgba(245,251,255,0.89) 0%, rgba(252,254,255,0.89)100%);
	background: -webkit-linear-gradient(left, rgba(245,251,255,0.89) 0%,rgba(252,254,255,0.89) 100%);
	background: linear-gradient(to right, rgba(245,251,255,0.89) 0%,rgba(252,254,255,0.89) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3fcfeff', endColorstr='#e3f5fbff',GradientType=1 );
}
.owl-carousel.st_v4_testimonial_slider .owl-nav .owl-next {
	background: -moz-linear-gradient(left, rgba(252,254,255,0.89) 0%, rgba(245,251,255,0.89) 100%);
	background: -webkit-linear-gradient(left, rgba(252,254,255,0.89) 0%,rgba(245,251,255,0.89) 100%);
	background: linear-gradient(to right, rgba(252,254,255,0.89) 0%,rgba(245,251,255,0.89) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e3fcfeff', endColorstr='#e3f5fbff',GradientType=1 );
}

.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav {
	position: absolute;
	left: 0;
	bottom: 55px;
	@include media-max(MD) {
		left: 50%;
		bottom: 20px;
	}
	@include media-max(SM) {
		bottom: 0;
		@include transform(translateX(-50%));
		display: block;
	}
}
.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav .owl-next {
	background: rgb(29,16,104);
	background: -moz-linear-gradient(left, rgba(29,16,104,1) 0%, rgba(29,16,104,1) 10%, rgba(48,31,142,1) 100%);
	background: -webkit-linear-gradient(left, rgba(29,16,104,1) 0%,rgba(29,16,104,1) 10%,rgba(48,31,142,1) 100%);
	background: linear-gradient(to right, rgba(29,16,104,1) 0%,rgba(29,16,104,1) 10%,rgba(48,31,142,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d1068', endColorstr='#301f8e',GradientType=1 );
}
.owl-carousel.st_v4_testimonial_slider.st_v4_testimonial_slider_v2 .owl-nav .owl-prev {
	background: rgb(48,31,142);
	background: -moz-linear-gradient(left, rgba(48,31,142,1) 0%, rgba(29,16,104,1) 90%, rgba(29,16,104,1) 100%);
	background: -webkit-linear-gradient(left, rgba(48,31,142,1) 0%,rgba(29,16,104,1) 90%,rgba(29,16,104,1) 100%);
	background: linear-gradient(to right, rgba(48,31,142,1) 0%,rgba(29,16,104,1) 90%,rgba(29,16,104,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#301f8e', endColorstr='#1d1068',GradientType=1 );
}

@each $name, $color in $colors {
	.slider-control-#{$name}.owl-carousel .owl-dot {
		background: rgba($color, 0.5)!important;
	}
	.slider-control-#{$name}.owl-carousel .owl-dot.active {
		background: $color!important;
	}
}



.st_v2_testimonial_slider {
	.owl-stage-outer {
		padding: 30px 30px;
		margin-left: -30px;
		width: calc(100% + 60px);
		@include media-max(SM) {
			padding: 0;
			margin-left: 0;
			width: 100%;
		}
	}
}
.owl-item {
	opacity: 0;
	@include transition(opacity 500ms);
}
.owl-item.active {
	opacity: 1;
}
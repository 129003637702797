//
// = About Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_about_area {
  padding: 270px 0;
  position: relative;
  overflow: hidden;
  @include media-max(LG) {
    padding: 130px 0 150px;
  }
  @include media-max(LG) {
    padding: 100px 0 100px;
  }
  @include media-max(XS) {
    padding: 80px 0 80px;
  }
  .st_about_side_img {
	  position: absolute;
	  left: 50vw;
	  width: calc(50vw + 180px);
	  top: 50%;
	  transform: translateY(-50%);
	  @include media-max(MD) {
			position: relative;
			left: 0;
			top: inherit;
			display: block;
			transform: translateY(0);
			width: 90%;
			margin-right: auto;
			margin-left: auto;
		}
	}
}
.st_about_single_full {
	width: 100%;
	margin-bottom: 150px;
	&:last-child {
		margin-bottom: 0;
	}
	@include media-max(MD) {
		margin-bottom: 100px;
	}
	@include media-max(XS) {
		margin-bottom: 100px;
	}
}

.st_left_about_shape {
	position: absolute;
	top: 25px;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	@include transform(skewY(-3deg));
	@include transform-origin(0);
	z-index: 0;
	span{
	  position: relative;
	  opacity: 0;
	  width: 100%;
	  margin-bottom: -3px;
	  top: 0;
	  width: 360px;
	}
	@include media-max(LG) {
		display: none;
	}
}
.st_left_about_shape.animated span {
	@include animation(stripeSlideInRight 2s forwards);
}
.st_left_about_shape span:nth-child(1) {
	right: 0;
	left: inherit;
	background: rgba(122, 184, 220, 0.48);
	z-index: 2;
	height: 161px;
}
.animated.st_left_about_shape span:nth-child(1) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}
.st_left_about_shape span:nth-child(2) {
	right: -154px;
	bottom: auto;
	background: #7AB8DC;
	z-index: 1;
	height: 166px;
	margin-top: -40px;
}
.animated.st_left_about_shape span:nth-child(2) {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}
.st_left_about_shape span:nth-child(3) {
	left: inherit;
	background: #3A97CC;
	height: 172px;
	right: -230px;
}
.animated.st_left_about_shape span:nth-child(3) {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}


.top-bottom-angle {
  position: relative;
  margin-bottom: 0;
  padding: 250px 0 200px;
  &::before,
  &::after {
		width: 100%;
		height: 140px;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		background-color: $color-white;
		background-size: cover;
  }

  &::before {
		-webkit-clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
		clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
		@supports not ((-webkit-clip-path: polygon(0% 100%, 100% 0%, 0% 0%)) or (clip-path: polygon(0% 100%, 100% 0%, 0% 0%))) {
	  	@include transform(skewY(-4deg));
			@include transform-origin(bottom left);
  	}
  }
  &::after {
  	top: auto;
		bottom: 0;
		-webkit-clip-path: polygon(0% 100%, 100% 101%, 100% 0%);
		clip-path: polygon(0% 100%, 100% 101%, 100% 0%);
		@supports not ((-webkit-clip-path: polygon(0% 100%, 100% 101%, 100% 0%)) or (clip-path: polygon(0% 100%, 100% 101%, 100% 0%))) {
			@include transform(skewY(-4deg));
			@include transform-origin(top right);
		}
  }
  @include media-max(LG) {
  	padding: 170px 0 180px;
		&::before {
			-webkit-clip-path: polygon(-100% 100%, 100% 0%, 0% 0%);
		  clip-path: polygon(-100% 100%, 100% 0%, 0% 0%);
	  }
	  &::after {
	  	-webkit-clip-path: polygon(-100% 100%, 100% 100%, 100% 0%);
		  clip-path: polygon(-100% 100%, 100% 100%, 100% 0%);
	  }
  }
  @include media-max(MD) {
  	padding: 130px 0 220px;
		&::before {
			-webkit-clip-path: polygon(-220% 100%, 100% 0%, 0% 0%);
		  clip-path: polygon(-220% 100%, 100% 0%, 0% 0%);
			@supports not ((-webkit-clip-path: polygon(-220% 100%, 100% 0%, 0% 0%)) or (clip-path: polygon(-220% 100%, 100% 0%, 0% 0%))) {
		  	@include transform(skewY(-2deg));
		  }
	  }
	  &::after {
	  	-webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
	  	clip-path: polygon(-220% 100%, 100% 100%, 100% 0%);
		  @supports not ((-webkit-clip-path: polygon(-220% 100%, 100% 100%, 100% 0%)) or (clip-path: polygon(-220% 100%, 100% 100%, 100% 0%))) {
		  	@include transform(skewY(-2deg));
		  }
	  }
  }

  @include media-max(SM) {
		&::before,
		&::after  {
	  	display: none;
	  }
	  padding: 80px 0 80px;
  }
}
.top-bottom-angle.top-bottom-angle-index {
	&::after {
		z-index: -1;
	}
}

.st_about_bubbles_logo{
	position: absolute;
	box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
	border-radius: 100%;
	background-color: #fff;
	text-align: center;
	animation-name: floating;
	transform-origin: center bottom;
	animation-iteration-count: infinite;
	animation-timing-function:ease-in-out;
	animation-duration: 7s;
	animation-fill-mode: both;
	-webkit-animation-name: floating;
	-webkit-transform-origin: center bottom;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function:ease-in-out;
	-webkit-animation-duration: 7s;
	-webkit-animation-fill-mode: both;
  &:nth-child(odd) {
    animation-delay: 2s;
	}
	&:nth-child(1){
		width: 100px;
		height: 100px;
		line-height: 100px;
		bottom: -55px;
		left: 0;
		img{
			position: relative;
			left: 8px;
			top: -2px;
		}
	}
	&:nth-child(2){
		width: 90px;
		height: 90px;
		line-height: 90px;
		bottom: 28%;
		left: 13%;
	}
	&:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6){
		width: 60px;
		height: 60px;
		line-height: 60px;
		top: 10%;
		left: 10px;
	}
	&:nth-child(4){
		top: auto;
		bottom: 10%;
		left: 35%;
	}
	&:nth-child(5){
		top: 0;
		left: 32%;
	}
	&:nth-child(6){
		top: 35%;
		left: 50%;
	}
	&:nth-child(7), &:nth-child(8), &:nth-child(9){
		top: -26%;
		left: 55%;
		width: 100px;
		height: 100px;
		line-height: 100px;
	}
	&:nth-child(8){
		top: 34%;
		right: 9%;
		left: auto;
	}
	&:nth-child(9){
		bottom: -15%;
		right: 16%;
		left: auto;
		top: auto;
	}
}


.st_about_bubbles {
  @include media-max(MD) {
		height: 261px;
		margin-bottom: 130px;
		position: relative;
		margin-top: 20px;
  }
}
//
// = Default Content Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_default_content{
	-ms-word-break: break-all;
	word-break: break-all;
	h2{
		margin: 0 0 30px;
		@include font-size(34);

	}
	h4{
		@include font-size(18);
		margin: 0 0 48px;
		font-family: $font-body;
		@include media-max(MD) {
			margin-bottom: 25px;
		}
	}
	p {
		line-height: 2;
		margin-bottom: 30px;
		@include media-max(MD) {
			margin-bottom: 20px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		@include media-max(MD) {
			margin-bottom: 20px;
		}
	}
	i {
		background-color: #B3AADC;
		color: #fff;
		width: 54px;
		height: 54px;
		line-height: 54px;
		text-align: center;
		border-radius: 100%;
		font-size: 1.5em;
		margin: 0 0 22px;
	}
	img {
		margin-bottom: 18px;
	}
	.st_default_btn {
		img {
			margin-bottom: 0;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			position: relative;
			margin-bottom: 12px;
			&::before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				display: inline-block;
				background-color: $color-emerald;
				color: $color-white;
				border-radius: 100%;
				font-size: 9px;
				width: 15px;
				height: 15px;
				line-height: 15px;
				text-align: center;
				margin-right: 15px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.st-ul-coloum-two {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
		}
		&.st-ul-coloum-three {
			columns: 3;
			-webkit-columns: 3;
			-moz-columns: 3;
		}
	}
	@include media-max(LG) {
		h2 {
			@include font-size(30);
		}
	}
	@include media-max(MD) {
		h2 {
			@include font-size(28);
		}
	}
	@include media-max(SM) {
		-ms-word-break: normal;
		word-break: normal;
		h2 {
			@include font-size(25);
		}
	}
}

.st_about_single_img {
  position: absolute;
  right: 0;
  top: 50%;
  width: 8vw;
  @include transform(translateY(-50%));
  overflow: hidden;
  border-radius: 15px;
	&.item_01{
		top: 60px;
		right: 176px;
		width: auto;
		@include transform(translateY(0));
	}
	&.item_02{
		top: 205px;
		right: 53px;
		width: auto;
		@include transform(translateY(0));
	}
	&.item_03{
		bottom: 93px;
		right: 210px;
		width: auto;
		top: inherit;
		@include transform(translateY(0));
	}
}

.st_about_right_box {
	position: relative;
	width: 400px;
	height: auto;
	border-radius: 100%;
	box-shadow: 0 3px 50px rgba(0, 0, 0, 0.41);
	z-index: 0;
	&::before  {
		background-color: $color-lochmara;
		width: 400px;
		height: 400px;
		position: absolute;
		left: auto;
		top: -45px;
		content: "";
		right: -40px;
		border-radius: 100%;
		z-index: -1;
	}
	.st_video_icon {
		.st_popup_youtube {
			background-color: rgba(0, 0, 0, 0.44);
			width: 130px;
			height: 130px;
			line-height: 130px;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			text-align: center;
		}
	}
	.iq-waves {
		height: 22rem;
		width: 22rem;
		.wave-1 {
		  background-color: rgba(56, 67, 104, 0.5);
		}
	}
	@include media-max(LG) {
		&::before {
			right: 0;
		}
	}
	@include media-max(SM) {
		&::before {
			display: none;
		}
	}
	
}


.st_content_item {
	border-radius: 5px;
	z-index: 0;
	padding: 100px 80px;
	position: relative;
	left: -50px;
	@include media-max(MD) {
		left: 0;
	}
	@include media-max(SM) {
		padding: 50px 35px;
	}
	&:before{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		background-color: $color-white;
		box-shadow: 0 0 80px rgba(0, 0, 0, 0.06);
		z-index: -1;
	}
	.st_sontent_wrap {
		.st_content_top {
			img {
				margin-bottom: 18px;
			}
			p {
				line-height: 2;
			}
		}
	}
	.st_content_bottom {
		ul {
			@include st_ul($padding: 0 60px);
			@include media-max(SM) {
				padding: 0;
			}
			li {
				color: $color-haiti;
				font-size: 0.875em;
				font-weight: 500;
				margin: 24px 0;
				position: relative;
				&::before {
					width: 25px;
					height: 25px;
					position: absolute;
					left: -55px;
					top: 0;
					content: "\f06b";
					background-color: #FFEDEC;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					font-size: 1em;
					color: #F46F6C;
					text-align: center;
					line-height: 25px;
					border-radius: 100%;
					@include media-max(SM) {
						  position: relative;
						  left: 0;
						  display: inline-block;
						  margin-right: 10px;
						  margin-bottom: 7px;
					}
				}
				&:first-child{
					margin-top: 0;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}
.st_solid_bg{
	background-color: $color-white;
	width: 300px;
	height: 600px;
	left: -100px;
	top: 22%;
	position: absolute;
	z-index: -2;
}
.st_content_bg{
	position: absolute;
	z-index: -2;
	top: -65px;
	left: -100px;
}

.st_single_right_img{
	min-width: 65vw;
	position: absolute;
	left: -80px;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
		max-width: 1600px;
		float: left;
	}
	@include media-max(SM) {
		min-width: 100%;
		position: relative;
		left: inherit;
		margin-top: 60px;
	}
	@include media-max(SM) {
		position: relative;
		left: inherit;
		margin-top: 60px;
	}
}

.st-right-full-half {
	position: absolute;
	bottom: 0;
	width: 50vw;
	right: 0;
	@include media-max(LG) {
		width: 40vw;
		&.half-middle-lg {
			width: 50vw;
		}
	}
}
.st_about_right_item {
	position: relative;
	.st_about_solid_bg {
	  background-color: rgba(245, 249, 253, 0.6);
	  width: 300px;
	  height: 497px;
	  left: auto;
	  top: 30%;
	  position: absolute;
	  right: -70px;
	  z-index: -1;
	}
	.st_about_image_bg {
		position: absolute;
		top: -55px;
		z-index: -1;
		left: -50px;
	}
	@include media-max(MD) {
		text-align: center;
		.st_about_image_bg {
			top: 0;
		}
	}
}

.st-special-thumbnail {
  width: 80%;
  box-shadow: 0 13px 13px rgba(0,0,0,0.015);
  border-radius: 34px;
}
.banner-height{
	height: 100vh;
}
.st_404_area {
	overflow: hidden;
}
.st_404_wrap {
	min-height: 750px;
	.st_404_img_item{
		z-index: 0;
		.st_404_img_single_item{
			width: 23vw;
			min-width: 250px;
			height: auto;
			border-radius: 7px;
			overflow: hidden;
			box-shadow: -10px -3px 37px rgba(0, 0, 0, 0.05);
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.st_404_img_single_item.st_404_02, .st_404_03{
			top: 0;
			left: -130px;
			z-index: -1;
			position: absolute;
			transform: rotate(-15deg);
		}
		.st_404_img_single_item.st_404_03{
			left: auto;
			right: -130px;
			transform: rotate(15deg);
		}				
	}
	
}
.st_404_content{
	h2{
		line-height: 1.29;
	}
}

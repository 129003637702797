//
// = Pricing styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st-pricing-switcher {
  margin-bottom: 60px;
  text-align: center;
  .fieldset {
		display: inline-block;
		position: relative;
		width: 500px;
		input[type="radio"] {
			position: absolute;
			opacity: 0;
		}
		label {
			position: relative;
			z-index: 1;
			width: 139px;
			cursor: pointer;
			font-size: 1.125em;
			color: #ADADC1;
			font-family: "Oxygen", sans-serif;
			text-transform: uppercase;
			font-weight: bold;
			margin: 0;
		}
		input[type="radio"]#yearly-1:checked ~ .st-pricing-underlines .st-pricing-underlines-2 {
			left: 175px;
		}
		.st-pricing-underlines-1 {
			width: 500px;
			height: 4px;
			position: absolute;
			left: 0;
			top: 50px;
			@include media-max(SM) {
				width: 100%;
				max-width: 100%;
			}
		}
		.st-pricing-underlines-2 {
			width: 300px;
			height: 4px;
			position: absolute;
			left: 25px;
			top: 50px;
			@include transition();
			@include media-max(SM) {
				width: 170px;
				max-width: 100%;
			}
		}
		@include media-max(SM) {
			width: 100%;
			max-width: 100%;
		}
  }
}
ul.st-pricing-list.st-bounce-invert{
	@include st_ul;
	ul.st-pricing-wrapper {
		position: relative;
		@include st_ul;
		.st-pricing-header {
			background-color: $color-medium-purple;
			padding: 18px 0;
			border-radius: 5px 5px 0 0;
			h3{
				font-size: 1em;
				color: $color-white;
				font-weight: 400;
				text-transform: capitalize;
				line-height: 1;
				margin-bottom: 0;
			}
		}
		.st-price {
			padding: 20px 0 10px;
			.st-currency{
				color: #140F30;
				font-size: 2.125em;
				font-family: $font-title;
				font-weight: bold;
			}
		}
		
		.st-pricing-body{
			ul.st-pricing-features{
				@include st_ul;
				li{
					display: block;
					margin: 0;
					text-transform: capitalize;
					color: $color-primary;
					padding: 8px 0px;
				}
			} 
		}
	                                                    
		
		.st-pricing-hover {
			h2{
				color: $color-white;
			}
			.st_default_btn.st-pricing{
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
				background-color: $color-gigas;
				color: $color-white;
				position: relative;
				top: 30px;
				@include transition();
				&:hover{
					background-color: #1ED9F9;
				}
			}
			position: absolute;
			background-color: $color-medium-purple;
			width: 100%;
			top: 0;
			height: 0;
			opacity: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: height 0.4s, opacity 0.4s;
			-webkit-transition: height 0.4s, opacity 0.4s;
			box-shadow: 0 20px 60px rgba(128, 102, 220, 0.4);
			border-radius: 5px;
			@include media-max(LG) {
				position: relative;
				top: inherit;
				height: auto;
				opacity: 1;
				display: block;
				box-shadow: none;
				padding-top: 20px;
				padding-bottom: 10px;
				background-color: transparent;
				h2 {
					display: none;
				}
				.st_default_btn.st-pricing{
					top: inherit;
					box-shadow: none;
				}
			}
		}
		.touch & {
			@include perspective($st-perspective);
		}
		&.is-switched {
			.is-visible {
				@include transform(rotateY(180deg));
				@include animation(st-rotate $st-animation-duration);
			}

			.is-hidden {
				@include transform(rotateY(0));
				@include animation(st-rotate-inverse $st-animation-duration);
				opacity: 0;
			}

			.is-selected {
				opacity: 1;
			}
		}
		&.is-switched.reverse-animation {
			.is-visible {
				@include transform(rotateY(-180deg));
				@include animation(st-rotate-back $st-animation-duration);
			}
			.is-hidden {
				@include transform(rotateY(0));
				@include animation(st-rotate-inverse-back $st-animation-duration);
				opacity: 0;
			}
			.is-selected {
				opacity: 1;
			}
		}

		> li {

			background-color: #F6F7FF;
			@include backface-visibility(hidden);
			
			&.is-ended::after {
				display: none;
			}
			&:hover {
				.st-pricing-hover{
					height: 100%;
					opacity: 1;
				}
			}
		}
		.is-visible { 
			position: relative;
			z-index: 0;
			padding: 0 0 23px;
		}
		.is-hidden { 
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 1;
			@include transform(rotateY(180deg)); 
		}
		.is-selected { 
			z-index: 3 !important;
		}
		
	}
	@include media-max(MD) {
		margin-bottom: 35px;
	}
}


// pricing v2
.st_pricing_packges{
	text-align: center;
	border: 1px solid #F3F3F3;
	padding: 60px 0;
	background-color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.st_pricing_packges_header{
		padding: 0 0 35px;
		.st_pack_name{
			background-color: $color-shamrock;
			color: $color-white;
			border-radius: 50px;
			display: inline-block;
			text-transform: uppercase;
			padding: 5px 20px;
			font-family: $font-title;
			margin: 0 0 20px;
			@include font-size(16);
		}
		.st_pack_name.yellow{
			background-color: $color-yellow-orange;
			color: $color-white;
		}
		.st_pack_name.pink{
			background-color: $color-pink;
			color: $color-white;
		}
		.st_pack_year{
			@include font-size(18);
		}
	}
	.st_packges_body{
		.pack_price{
			margin: 0 0 30px;
			h2{
				font-weight: 700;
			}
		}
		.st_pack_feature_list{
			@include st_ul;
			li{
				margin:15px 0;
			}
			
		}
	}
	.st_pack_footer{
		padding:30px 0 0;
		.st_default_btn.st_transparent{
			background-color: transparent;
			border: 1px solid $color-aqua;
			color: $color-haiti;
			min-width: 140px;
			padding: 14px 10px;
			font-weight: 600;
			@include transition();
			&:hover{
				background-color: $color-aqua;
				color: $color-white;
			}
		}
	}
	&.st_pricing_bg{
		color: #fff;
		border: none;
		box-shadow: 0 11px 30px rgba(28, 76, 223, 0.36);
		position: relative;
		overflow: hidden;
		.st_default_btn.st_transparent{
			background-color: transparent;
			border: 1px solid $color-white;
			color: $color-white;
			@include transition();
			&:hover{
				background-color: $color-aqua;
				border: 1px solid $color-aqua;
				color: $color-white;
			}
		}
		.pack_price{
			h2{
				color: #fff;
			}
		}
		.st_most_popular {
			position: absolute;
			top: 32px;
			font-size: .875em;
			width: 181px;
			padding: 4px 0;
			right: -36px;
			transform: rotate(40deg);
			background-color: $color-white;
			color: $color-aqua;
		}
	}
}
.st_pricing_table_shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
  img {
  	width: 100%;
  }
}
.st_pricing_table_area {
	position: relative;
}
.st_pricing_packges.orange-pricing-bg {
	@extend %orange-pricing-bg;
	box-shadow: 0 11px 30px rgba($color-orange, 0.36);
}
.st_pricing_packges.aqua-pricing-bg {
	@extend %aqua-pricing-bg;
	box-shadow: 0 11px 30px rgba($color-aqua, 0.36)
}
.st_pricing_packges_highlight.st_pricing_packges {
	position: relative;
	top: -16px;
	height: calc(100% + 32px);
}



// switching price
.st-pricing-btn-style-wrapper {
	.st-pricing-switcher {
		margin-bottom: 40px;
		.fieldset{
			display: flex;
			position: relative;
			width: 208px;
			border: 1px solid $color-medium-purple;
			border-radius: 50px;
			height: 42px;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			.st-pricing-underlines-2 {
				background: $color-medium-purple;
	  		color: $color-white;
				width: 104px;
				height: 41px;
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 50px 0px 0 50px;
				@include transition();
			}
			input[type="radio"]{
				position: absolute;
				opacity: 0;
			}
			input[type=radio]:checked + label{
				color: #fff;
			}
			label {
				position: relative;
				z-index: 1;
				font-size: .813em;
				color: #333;
				display: flex;
				height: 42px;
				line-height: 42px;
				justify-content: center;
				@include transition();
			}
			input[type="radio"]#yearly-1:checked ~ .st-pricing-underlines .st-pricing-underlines-2 {
				background: $color-medium-purple;
	  		color: $color-white;
				left: 104px;
				border-radius: 0 50px 50px 0px;
				color: $color-white;
			}
		}
	}
	ul.st-pricing-list.st-bounce-invert{
		ul.st-pricing-wrapper {
			.st-pricing-header {
				background-color: #F1EEFB;
				padding: 14px 0;
				border-radius: 5px 5px 0 0;
				h3{
					@include font-size(14);
					color: #5040A0;
					font-weight: 600;
					text-transform: uppercase;
					font-family: $font-body;
					margin-bottom: 0;
				}
			}
			.st-price {
				padding: 30px 0 20px;
				p{
					color: #BABABA;
					@include font-size(14);
				}
			}
			
			.st-pricing-body{
				ul.st-pricing-features{
					li{
						display: block;
						margin: 20px 0;
						text-transform: capitalize;
						padding:0;
						&:last-child{
							margin-bottom: 0;
						}
						&:first-child{
							margin-top: 0;
						}
					}
				} 
			}
		                                                    
			.is-visible { 
				padding: 0;
			}
			.cs-pricing-footer{
				padding: 35px 0 45px;
				.st_default_btn.st_transparent{
					padding: 12px 25px;
					min-width: 154px;
				}

			}
			> li {
				background-color: #fff;
				box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
				@include transition();
				border-radius: 10px;
			}
			&:hover > li{
				box-shadow: 0 10px 80px rgba(0, 0, 0, 0.07);
			}
			
		}
	}
}



.st_pricing_table_waraper {
	display: flex;
	text-align: center;
	width: 70%;
	margin: 0 auto;
	box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	.st_pricing_single_item {
		flex: 0 0 33.33%;
		border-right: 1px solid rgba(112, 112, 112, .07);
		.st_pricing_title{
			padding: 15px 0;
			h3{
				font-family: $font-body;
				@include font-size(14);
				margin-bottom: 0;
				@include media-max(SM) {
					height: 48px;
					overflow: hidden;
					width: 80%;
					display: block;
					margin: auto;
				}
			}
			&.color_blue{
				h3{
					color: $color-science-blue;
				}
			}
		}
		.st_pricing_package {
			height: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-bottom: 1px solid rgba(112, 112, 112, .07);
			p{
				color: $color-haiti;
				@include font-size(14);
			}
			i{
				color: $color-emerald;
			}
			&.st_extra_height{
				height: 100px;
				background-color: $color-white !important;
			}
			.st_default_btn{
				min-width: 130px;
				padding: 10px 0;
				border-radius: 10px;
				@include media-max(SM) {
					padding: 10px 10px;
					min-width: inherit;
				}
			}
			.st_default_btn.st_transparent{
				background-color: transparent;
			}
			&:nth-child(odd) {background-color: $color-polar};
			&:nth-child(even) {background-color: $color-white}
		}
	}
	@include media-max(LG) {
		width: 100%;
	}
}
//
// = Section Heading styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_section_heding_title {
  text-align: center;
  margin-bottom: 80px;
  img {
  	margin-bottom: 18px;
  }
  h2 {
  	@include font-size(34);
  	@include media-max(SM) {
		@include font-size(22);
  	}
  }
  > p {
  	margin-bottom: 5px;
  }
  @include media-max(LG) {
	margin-bottom: 65px;
  }
  @include media-max(MD) {
	margin-bottom: 55px;
  }
  @include media-max(SM) {
	margin-bottom: 45px;
  }
}
.st_section_heding_title.st_white_color{
	h2{
		color: #fff;
	}
	p{
		color: #D6D6D6
	}
}
.st_section_title_icon.bg-icon {
	background-color: rgba(3, 102, 214, 0.1);
	color: $color-science-blue;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	border-radius: 100%;
	margin: 0 auto;
	margin-bottom: 18px;
	img {
  	margin-bottom: 0;
  }
}
//
// = Screenshot ShowCase Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_screenshot_warap{
	.st_screenshot_tab {
	  position: relative;
		margin-bottom: 75px;
		@include media-max(SM) {
			margin-bottom: 40px;
	  }
		&:before{
			width: 100%;
		  height: 5px;
		  position: absolute;
		  left: 0;
		  top: 35px;
		  content: "";
		  background: -moz-linear-gradient(left, rgba($color-white, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba($color-white, 0.8) 100%);
		  background: -webkit-linear-gradient(left, rgba($color-white, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba($color-white, 0.8) 100%);
		  background: linear-gradient(to right, rgba($color-white, 0.8) 0%, rgba(255, 238, 225, 0.8) 50%, rgba(255, 255, 254, 0.8) 99%, rgba($color-white, 0.8) 100%);
		  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ccffffff',GradientType=1 );
		  @include media-max(SM) {
				display: none;
		  }
		}
	  .nav{
	  	display: block;
	  }
	  .nav-pills > li a.active{
	  	color: $color-orange !important;
	  	position: relative;
	  	background-color: transparent;
	  	@include transition();
	  	&:before{
  		  width: 300px;
			  height: 3px;
			  position: absolute;
			  left: 50%;
			  top: 35px;
			  content: "";
			  transform: translateX(-50%);
			  background: $color-white;
			  background: -moz-linear-gradient(left, $color-white 0%, $color-orange 50%, $color-white 100%);
			  background: -webkit-linear-gradient(left, $color-white 0%, $color-orange 50%, $color-white 100%);
			  background: linear-gradient(to right, $color-white 0%, $color-orange 50%, $color-white 100%);
			  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='$color-white',GradientType=1 );
			  @include media-max(SM) {
					display: none;
			  }
	  	}
	  }
	  ul.nav.nav-pills{
	  	@include st_ul;
	  	li{
				display: inline-block;
				margin: 0 45px;
				text-transform: capitalize;
				@include media-max(MD) {
          margin: 0 15px;
        }
        @include media-max(SM) {
					margin: 6px 15px;
			  }
	  		a{
	  		  color: $color-haiti;
				  @include font-size(14);
				  font-family: $font-title;
				  font-weight: bold;
				  display: block;
				  text-transform: uppercase;
				  padding: 0;
				  @include transition();
	  		}
	  	}
	  }

	}
	.st_tab_single_img {
		display: inline-block;
		position: relative;
		background-color: $color-white;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.19);
		img {
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
			padding: 0 30px;
			position: relative;
			z-index: 2;
			width: calc(100% - 60px);
		}
		@include media-max(MD) {
			img {
				padding: 0;
				box-shadow: none;
				width: 100%;
			}
			&::before {
				display: none;
			}
		}
	}
}
//
// = Buttons default styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_default_btn{
  border-radius: 3px;
  background: $color-gigas;
  color: #fff;
  border: 1px solid $color-white;
  font-size: 0.813em;
  font-weight: 600;
  border: 0px solid $color-white;
  padding: 19px 25px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  letter-spacing: 1px;
  line-height: 1;
  margin-right: 25px;
  position: relative;
	@include transition();
	&:hover,
	&:focus,
	&:active {
		color: $color-white;
	}
	> i {
		font-size: 1.4em;
		line-height: 0;
		position: relative;
		top: 2px;
		margin-right: 4px;
	}
	span {
		i {
			margin-right: 5px;
		}
		&.st-btn-tag {
			position: absolute;
			top: 0;
			right: 0;
			display: inline-block;
			background-color: $color-shamrock;
			padding: 8px 10px;
			border-radius: 4px;
			@include transform(translate(50%, -14px));
			font-size: 0.625rem;
		}
	}
	@include media-max(XS) {
		margin-right: 0;
		margin-bottom: 15px;
	}
}
.st_default_btn.st_transparent{
	background: transparent;
	color: $color-white;
	border: 1px solid currentColor;
	box-shadow: none;
	&:hover {
		color: $color-white !important;
		box-shadow: none;
	}
}
.st_default_btn.st_plain_text {
	background: transparent;
	color: $color-white;
	border: none;
	box-shadow: none;
	text-shadow: none;
	&:hover {
		box-shadow: none;
	}
}
.st_default_btn.st_icon_btn {
	background: transparent;
	color: $color-white;
	border: none;
	box-shadow: none;
	text-shadow: none;
	padding: 0;
	&:hover {
		color: $color-white !important;
		box-shadow: none;
	}
	span {
	  background-color: $color-white;
	  width: 28px;
	  height: 28px;
	  line-height: 28px;
	  text-align: center;
	  border-radius: 100%;
	  display: inline-block;
	  margin-right: 10px;
	  i {
			color: $color-primary;
			font-style: 0.8em;
			margin-right: 0;
	  }
	  img {
		width: auto;
		max-width: 100%;
		display: inline-block;
	  }
	}
	p {
		font-size: .813em;
		font-weight: 600;
		color: $color-white;
		display: inline-block;
		margin: 0;
	}
}
.st_default_btn.bg-emerald-gredient {
	@extend %emerald-btn-gradient;
	&:hover,
	&:focus,
	&:active {
		color: $color-white;
	}
}
.st_default_btn.st_btn_shadow{
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.st_default_btn.st_btn_large_shadow{
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}
.st_default_btn.st_btn_medium_shadow{
	box-shadow: 0 20px 50px rgba(0, 0, 0, 0.06);
}
.st_default_btn.st_btn_round{
	border-radius: 50px;
}
.st_default_btn.st_btn_square{
	border-radius: 0;
}
.st_default_btn:last-child{
	margin: 0;
}
.st_default_btn.st_default_btn_extra_pd {
	padding-right: 40px;
	padding-left: 40px;
}
.st_default_btn.st_default_btn_full,
.st_default_btn_full {
	width: 100%;
}

.app_store,
.st_google_paly {
	img {
		max-width: 132px;
		width: 100%;
		display: inline-block;
	}
}
.st_commerce_shop_thumb {
	@include transition;
	overflow: hidden;
	position: relative;
	border-radius: 3px;
	margin-bottom: 20px;
	min-height: 430px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
		background-color: $color-white !important;
	}
	img{
		position: relative;
		top: 0;
		@include transition;
	}
	.st_commerce_add_to_cart {
		position: absolute;
		bottom: -100px;
		@include transition;
		width: 100%;
		display: flex;
		@include media-max(MD) {
			bottom: 0;
		}
		a.st_default_btn {
			flex: 0 0 50%;
			border-radius: 0;
			padding-left: 10px;
			padding-right: 10px;
			margin: 0;
		}
	}
	&:hover img{
		top: -20px;
	}
	&:hover .st_commerce_add_to_cart{
		bottom: 0;
	}
}
.st_commerce_shop_content{
	ul{
		li{
			display: inline-block;
			@include font-size(14);
			color: #FFB300;
		}
	}
}


// SINGLE SHOP

#st_shop_slider_1 {
	margin-bottom: 30px;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
}

#st_shop_slider_2 {
	.st_shop_single_slider {
		width: 70px;
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		
		
		@include transition;
	}
	img{
		width: 40px;
		height: 40px;
	}
	&.owl-carousel .owl-stage-outer {
		overflow: visible !important;
	}
	.owl-item.active{
		box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
		&.current{
			box-shadow: none;
			background-color: $color-polar;
		}
	}

}


.st_shop_review{
	ul{
		display: inline-block;
		li{
			display: inline-block;
			color: #FFAF24;
		}
	}
}


.shop-quantity {
  float: left;
  position: relative;
}
.st_count_input{
	input {
	  width: 140px;
	  padding: 10px;
	  @include font-size(20);
	  border: 1px solid #EBEBEB;
	}
	.incr-btn {
	  position: absolute;
	  top: 50%;
	  right: 25px;
	  color: $color-aqua;
	  @include font-size(24);
	  text-decoration: none;
	  transform: translateY(-50%);
	  &:first-child{
	  	right: auto;
  		left: 25px;
	  }
	}
}
#st_shop_submit_btn {
	font-weight: 600;
	border: none;
	padding: 15px 30px;
	letter-spacing: 1px;
	cursor: pointer;
	display: inline-block;
	border-radius: 3px;
	@include transition;
	color: $color-white;
	background-color: $color-aqua;
}

.st_shop_share_product{
	ul.st_social_icon{
		li{
			margin: 0 10px;
			display: inline-block;
			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}
			a{
				color: $color-haiti;
			}
		}
	}

}

.st_inner_radio_wrap{
	display: flex;
	.st_shop_radio_input {
		position: relative;
		border-radius: 100%;
		[type="radio"]:checked,
		[type="radio"]:not(:checked){
			position: absolute;
  			left: -9999px;
		}
		[type="radio"]:checked + label, 
		[type="radio"]:not(:checked) + label {
		  position: relative;
		  cursor: pointer;
		  display: block;
		  background-color: transparent;
		  margin: 0;
		  width: 27px;
		  height: 27px;
		  border-radius: 100%;
		  box-shadow: 0 14px 30 rgba(0, 0, 0, 0.18);
		  @include transition;
		}
		[type="radio"]:checked + label:after, 
		[type="radio"]:not(:checked) + label:after {
		  content: "\f00c";
		  font-family: "Font Awesome 5 free";
		  font-weight: 900;
		  color: $color-white;
		  position: absolute;
		  top: 4px;
		  right: 6px;
		  border-radius: 100%;
		  @include font-size(14);
		  @include transition;
		}
		[type="radio"]:not(:checked) + label:after {
		  opacity: 0;
		  -webkit-transform: scale(0);
		  transform: scale(0);
		}
		[type="radio"]:checked + label:after {
		  opacity: 1;
		  -webkit-transform: scale(1);
		  transform: scale(1);
		}
	}
}
//
// = Promo Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_single_prom_item {
  display: block;
  .st_promo_content {
  	h3 {
  		margin-bottom: 20px;
  	}
  	p {
			font-size: 0.875em;
			line-height: 2;
      @include media-max(LG) {
        br {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
  	}
  }
}
.st_promo_icon {
	border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #5040a0;
  text-align: center;
  margin-bottom: 25px;
  display: inline-block;
  @include transition();
}
.st_promo_icon.st_promo_icon_bg {
  display: inline-block;
  margin: 0 0 70px;
  position: relative;
  background: none;
  &::before {
    width: 170px;
    height: 137px;
    position: absolute;
    left: 65%;
    top: 45%;
    content: "";
    transform: translate(-50%, -50%);
    background-image: url(../images/v4/promo-bg.png);
    z-index: 0;
  }
  img {
    position: relative;
    z-index: 1;
  }
}
.st-icon-gradient {
	background-size: 200% auto;
	&:hover {
  	  background-position: center right;
  }
}
.st_single_prom_item {
  &:hover {
    .st-icon-gradient {
      background-position: center right;
    }
  }
}
.st-icon-gradient {
  &.gigas-icon-gradient {
    @extend %gigas-icon-gradient;
  }
  &.orange-icon-gradient {
    @extend %orange-icon-gradient;
  }
}

// Promo V2
.st_single_prom_item_v2.st_single_prom_item {
  .st_promo_icon {
    display: inline-block;
    margin: 0 auto 30px;
    position: relative;
    background: none;
  }
  .st_promo_content {
    h3 {
      margin-bottom: 20px;
    }
    p {
      color: $color-primary;
    }
  }
}
.st_single_prom_item .st_promo_counter {
  background-color: $color-white;
  position: absolute;
  width: 42px;
  height: 42px;
  line-height: 42px;
  top: -20px;
  right: -25px;
  box-shadow: 0 3px 6px rgba($color-black, 0.1);
  border-radius: 100%;
}
.st_promo_center {
  .st_single_prom_item {
    text-align: center;
    .st_promo_icon {
      display: inline-block;
      margin: 0 auto 30px;
      position: relative;
      background: none;
    }
  }
}
.st_single_prom_item.st_single_prom_item_v3 {
  .st_promo_icon {
    width: 212px;
    height: 212px;
    overflow: hidden;
    margin-bottom: 50px;
    img {
      height: 100%;
      width: auto;
      object-fit: cover;
      object-postion: center center;
    }
    @include media-max(SM) {
      margin-bottom: 25px;
    }
    @include media-max(XS) {
      margin-bottom: 15px;
    }
  }
}
.st_single_prom_item.st_single_prom_item_v4 {
  padding: 55px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.04);
  .st_promo_icon {
    width: inherit;
    height: 100px;
    img {
      width: auto;
      height: 100%;
    }
  }
  .st_promo_content {
    h3 {
      margin-bottom: 30px;
      font-weight: bold;
    }
  }
}

.overlep-content-wrap {
  box-shadow: 0 3px 76px rgba(0, 0, 0, 0.05);
  padding: 100px;
  position: relative;
  top: -75px;
  margin-left: -8%;
  margin-right: -8%;
  margin-bottom: 115px;
  .st_section_heding_title {
    width: 70%;
    margin: 0 auto 80px;
  }
  @include media-max(LG) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 100px 30px;
  }
  @include media-max(SM) {
    padding-bottom: 50px;
    margin-bottom: 30px;
  }
}

.st_single_prom_item.st_single_prom_item_5 {
  .st_promo_icon{
    width: 198px;
    height: 198px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: $color-polar;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid $color-white;
    box-shadow: 0 3px 20px rgba(236, 248, 255, 1);
    img{
      height: 100%;
      width: auto;
      object-fit: cover;
      object-postion:center center;
    }
  }
  .st_promo_content{
    p{
      font-size: 1em;
    }
  }
}
.st_single_prom_item_v5 {
  .st_promo_icon {
    margin: 0 auto 50px;
    height: inherit;
    width: inherit;
    line-height: inherit;
  }
}

.promo-after-background {
  &::after {
    content: "";
    display: block;
    height: 40%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-polar;
    z-index: -1;
  }
}
//
// = Counter Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_counter_right {
  h2 {
		font-size: 3.000em;
		font-weight: bold;
		font-family: "Poppins", sans-serif;
		margin: 0 0 18px;
  }
  h4 {
  	color: $color-primary;
	  font-weight: 500;
	  font-size: 1.125em;
	  font-family: "Poppins", sans-serif;
  }
}

.st_single_counter {
  display: flex;
  border: 1px solid $color-st-concrete;
  padding: 30px 20px 30px 35px;
  @include media-max(LG) {
    display: block;
    padding: 30px 20px;
  }
	.st_counter_left {
		margin-top: 5px;
		margin-right: 20px;
		@include media-max(LG) {
	    margin-bottom: 17px;
	    margin-right: 0;
	    text-align: center;
	  }
	}
	.st_counter_right {
		h2 {
			font-size: 1.750em;
			font-weight: bold;
			margin: 0 0 5px;
		}
	  p {
	  	margin: 0;
	  }
	  @include media-max(LG) {
	    text-align: center;
	  }
	}
	@include media-max(MD) {
    margin-bottom: 30px;
  }
}

.st_stats_area{
	overflow: hidden;
	text-align: center;
	.st_stats_waraper{
		padding: 100px 0;
		margin-right: calc((-100vw + 100%) / 2);
  		padding-right: calc((100vw - 100%) / 2);
  		border-radius: 620px 0 0 620px;
		.st_stats_content{
			margin-left: 74px;
			h2{
				font-size: 3em;
				color: #fff;
				margin-bottom: 20px;
				line-height: 1;
			}
			p{
				color: #D4D4E9;
				@include font-size(18);
				letter-spacing: 1px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		@include media-max(MD) {
			padding-right: 0;
			margin-right: 0;
			border-radius: 0;
			.st_stats_content {
				margin-left: 0;
				margin-bottom: 50px;
			}
		}
	}
}
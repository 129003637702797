.st_doc_menu{
	background-color: $color-polar;
	border-radius: 3px;
	position: relative;
	z-index: 5;
	max-height: calc(100vh - 120px);
	overflow: auto;
	ul{
		li{
			display: block;
			&:hover{
				background-color: $color-white;
			}
			&:first-child{
				margin-top: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
			a{
				display: block;
				padding: 10px 13px;
				color: $color-haiti;
				@include font-size(20);
				i{
					color: $color-aqua;
					margin-right: 13px;
					@include transition();
				}
				@include media-max(LG) {
					@include font-size(16);
				}
			}
		}
	}
	@include media-max(MD) {
		position: relative !important;
		top: inherit !important;
	}
}
ul.st_doc_submenu{
	display: none;
	padding: 15px 22px 15px 36px;
	li{
		margin: 15px 0;
		padding: 0;
		a{
			padding: 0;
			@include font-size(16);
			&:hover{
				color: $color-aqua;
			}
		}
		i{
			margin-right: 7px !important;
		}
		.st_doc_submenu {
			padding: 15px 22px 15px 30px;
			li {
				margin: 8px 0;
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.st_doc_content{
	p{
		line-height: 2;
	}
	@include media-max(SM) {
		word-wrap: break-all;
	}
}
.st_doc_wrap{
	ul{
		li{
			margin: 10px 0;
			position: relative;
			&:before{
				content: "";
				left: -25px;
				top: 7px;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				position: absolute;
				background-color: $color-aqua;
			}
			&:first-child{
				margin-top: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
			span{
				color: $color-haiti;
			}
		}
	}
	a {
		color: $color-aqua;
	}
	p.st-note {
		background-color: #0a0a0a;
		padding: 15px 20px;
		border-left: 3px solid red;
		border-right: 3px solid red;
	}
}


//
// = Faqs Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_faq_tab_waraper {
	.st_faqs_tab {
		position: relative;
		margin-bottom: 75px;
		text-align: center;
	  .nav{
	  	display: block;
	  }
	  .nav-pills {
	  	@include st_ul();
	  	& > li {
	  		display: inline-block;
			margin: 0;
			text-transform: capitalize;
			@include media-max(MD) {
			margin: 10px 0;
			}
	  		a {
	  			color: $color-primary;
				  font-weight: 600;
				  display: block;
				  text-transform: uppercase;
				  padding: 5px 20px;
				  @include transition();
	  			&.active {
	  				color: $color-lochmara;
				  	position: relative;
				  	background-color: rgba($color-lochmara, 0.05);
				  	border-radius: 50px;
				  	@include transition();
	  			}
	  		}
	  	}
	  }
	}
}

.st_faq_item{
	.st_faq_single_item{
		i {
			color: $color-lochmara;
		}
	}
}


// apply background color 
@each $name, $color in $colors {
  .nav-pills.nav-pills-bg-#{$name}  {
  	& > li {
  		a.active {
  			color: $color !important;
  			background-color: rgba($color, 0.05)!important;
  		}
  	}
  }
  
}
//
// = Reward Block Styles
//
// @package: SassTrend
// @version: 1.7
//
// ------------------------------------------------------------------------- */

.st_rewards_thumb{
	position: relative;
	&:before{
		width: 98%;
		height: 100%;
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		box-shadow: 0 3px 50px rgba(0, 0, 0, 0.16);
		border-radius: 50px;
		transform: translate(-50%, -50%);
	}
	width: 350px;
}

.reward-thumbnail {
    position: relative;
    z-index: 1;
}
.st_rewards_icon{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	.st_rewards_single_icon{
		position: absolute;
		background-color: #ffffff;
		border-radius: 100%;
		width: 104px;
		height: 104px;
		line-height: 104px;
		display: block;
        text-align: center;
        left: 123px;
		top: 120px;
		box-shadow: 0 0px 40px 1px rgba(0,0,0,0.1);
        @include transition(all 0.9s ease-in 0.4s);
        &.rewards_icon_5,
        &.rewards_icon_6,
        &.rewards_icon_7,
        &.rewards_icon_8 {
            left: auto;
            right: 123px;
		}
		&.rewards_icon_4 {
			img {
				width: 45%;
			}
		}
		&.rewards_icon_8 {
			img {
				width: 70%;
			}
		}
		&.rewards_icon_7 {
			img {
				width: 60%;
			}
		}
		&.rewards_icon_1,
		&.rewards_icon_3 {
			img {
				width: 80%;
			}
		}

        &.animated {
            &.rewards_icon_1,
            &.rewards_icon_5{
                left: -40%;
                top: 50%;
            }
            &.rewards_icon_2, 
            &.rewards_icon_6{
                left: -85%;
                top: 43%;
            }
            &.rewards_icon_3, 
            &.rewards_icon_7{
                left: -85%;
                top: 22%;
            }
            &.rewards_icon_4, 
            &.rewards_icon_8{
                left: -48%;
                top: 15%;
                width: 61px;
                height: 61px;
                line-height: 61px;
            }
            &.rewards_icon_5{
                right: -40%;
                left: auto;
            }
            &.rewards_icon_6{
                right: -85%;
                left: auto;
            }
            &.rewards_icon_7{
                right: -85%;
                left: auto;
            }
            &.rewards_icon_8{
                right: -48%;
                left: auto;
            }
		}
		@include media-max(MD) {
			display: none;
		}
        img {
			width: 90%;
		}
	}
}

// reward v2
.st_rewards_single_icon.st_rewards_icon_2 {
    background-color: $color-white;
    width: 100px;
    height: 100px;
    line-height: 100px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.07);
}

// st rewards map
.st_rewards_map {
	width: 250px;
	height: 250px;
	line-height: 236px;
	border-radius: 100%;
	margin: 0 auto;
	box-shadow: 0 0 0 20px #fff, 0 0 0 22px $color-picton-blue;
	img{
		width: 144px;
		height: auto;
	}
}
.st_rewards_single_img_item{
	position: absolute;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	overflow: hidden;
	width: 70px;
	height: 70px;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&.st_rewards_1{
		width: 121px;
		height: 121px;
		 left: -37%;
  		bottom: -55%;
	}
	&.st_rewards_2{
		left: -29%;
		top: -5%;
	}
	&.st_rewards_3{
		left: -5%;
		top: -30%;
	}
	&.st_rewards_4{
		left: -9%;
		top: 53%;
	}
	&.st_rewards_5, &.st_rewards_6{
		left: 20%;
		top: -10%;
		width: 89px;
		height: 89px;
	}
	&.st_rewards_6{
		left: 10%;
		top: auto;
		bottom: -45%;
	}
	&.st_rewards_7, &.st_rewards_8{
		right: 13%;
		top: -45%;
		width: 83px;
		height: 83px;
	}
	&.st_rewards_8{
		right: -37%;
		top: -60%;
	}
	&.st_rewards_9{
		right: -13%;
		top: 0%;
	}
	&.st_rewards_10{
		right: 7%;
		bottom: -15%;
		width: 101px;
		height: 101px;
	}
	&.st_rewards_11{
		right: -12%;
		bottom: -48%;
		width: 67px;
		height: 67px;
	}
	&.st_rewards_12{
		right: -30%;
		bottom: 0;
		width: 85px;
		height: 85px;
	}
}
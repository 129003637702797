//
// = Feature Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_feature_item {
  border-radius: 10px;
  width: 100%;
  max-width: 313px;
  padding: 60px 45px;
  position: relative;
  margin-bottom: 60px;
  h3 {
  	margin: 0 0 29px;
  }
  p {
		font-size: 0.875em;
		line-height: 1.9;
		margin-bottom: 0;
  }
  @include media-max(MD) {
    padding: 40px 30px;
  }
  @include media-max(LG) {
    margin: 0 auto 90px;
  }
}
.feature_item_icon {
  border-radius: 100%;
  background-color: $color-primary;
  color: $color-white;
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
  position: absolute;
  top: 35px;
  left: -43px;
  
  @include media-max(LG) {
    left: 25px;
    top: -50px;
  }

}
.feature-box-shadow-black {
  box-shadow: 0 5px 30px rgba($color-black, 0.07);
}


// skype features
.st_inner_feature_item{
  background-color: $color-white;
  display: flex;
  padding: 25px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba($color-black, 0.05);
  margin-bottom: 40px;
  .st_inner_feature_icon{
    margin-right: 14px;
    flex: 0 0 auto;
    font-size: 1.125em;
    background-color: $color-white;
    color: $color-perison-blue;
    width: 49px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    border-radius: 100%;
  }
  .st_inner_feature_content{
    margin-top: 9px;
    h3{
      font-size: 1.250em;
      margin-bottom: 20px;
      @include media-max(LG) {
        @include font-size(16);
      }
    }
    p{
      @include font-size(14);
      line-height: 1.7;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include media-max(MD) {
    margin-bottom: 25px;
  }
  &.st_trasnparent_item {
    background: transparent;
    box-shadow: none;
    margin-bottom: 25px;
    padding-top: 0;
    @include media-max(LG) {
      padding: 15px;
    }
    @include media-max(MD) {
      padding: 10px;
    }
    @include media-max(SM) {
      margin-bottom: 35px;
      padding: 0;
    }
  }
}

.st_design_feature_item {
  .st_design_feature_img {
    height: 165px;
    margin-bottom: 25px;
  }
  .st_design_feature_content {
    h3 {
      font-size: 1.5em;
      font-weight: 400;
      margin-bottom: 30px;
    }
    p {
      line-height: 2;
    }
  }
}


// feature v2 - trello
.st_feature_item_v2 {
  margin-bottom: 60px;
  i{
    font-size: 1.5em;
    margin-bottom: 30px;
    border-radius: 3px;
    background-color: #EBF8FF;
    color: $color-lochmara;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
  }
  h3{
    font-size: 1.250em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p{
    line-height: 2;
  }
}

// feature v3 - sassPay
img.feature-force-large-thumb {
  max-width: 1600px;
  float: right;
  width: 50vw;
  @include media-max(MD) {
    max-width: 100%;
    float: none;
    width: auto;
  }
}
.st_feature_right_item{
  padding: 50px 0;
  position: relative;
  @include media-max(MD) {
    position: relative;
    padding-left: 75px;
    width: 100%;
    padding-bottom: 0;
  }
  .st_feature_content{
    position: relative;
    z-index: 0;
    margin-bottom: 50px;
    &:last-child{
      margin: 0;
    }
    &::before{
      width: 48px;
      height: 48px;
      position: absolute;
      left: -75px;
      top: 0;
      content: "\f06b";
      background-color: #EBF8FF;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 1.5em;
      color: #0079BF;
      text-align: center;
      line-height: 45px;
      border-radius: 5px;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    }
    &::after{
      width: 2px;
      height: 100%;
      position: absolute;
      left: -50px;
      top: 50px;
      content: "";
      background-color: rgba(255, 255, 255, 0.2);
      z-index: -1;
    }
    &:last-child::after{content: none;}
    h3{
      font-size: 1.250em;
      color: $color-white;
      margin-bottom: 25px;
    }
    p{
      color: #CDDFE9;
      line-height: 2;
    }
  }
}

.st_feature_left_item {
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  padding: 60px 39px;
  h2 {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 35px;
  }
  p {
    font-size: 0.875em;
    line-height: 1.9;
    margin-bottom: 40px;
  }
  .read_more_btn {
    color: $color-haiti;
    font-size: .813em;
    font-weight: 600;
    img {
      margin-left: 20px;
    }
  }
}

.st_feature_single_item {
  img{
    margin-bottom: 20px;
  }
  h3{
    @include font-size(14);
    margin-bottom: 16px;
    font-family: $font-body;
    font-weight: 600;
  }
  p{
    @include font-size(14);
    line-height: 1.9;
  }
  margin-bottom: 70px;
}

.st_simple_feature_item {
  margin-bottom: 65px;
  img {
    margin-bottom: 30px;
  }
  p {
    font-size: 1.125em;
  }
}
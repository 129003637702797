.st_coming_soon_page {
	overflow: hidden;
	.st_coming_wrap {
		min-height: 700px;
		.st_coming_soong_content {
			max-width: 100%;
		}
		.st_coming_logo {
			@include media-max(XS) {
				max-width: 200px;
			}
		}
	}
}
.st_countdown {
	font-family: $font-title;
	@include media-max(XS) {
		.st_item {
			flex: 0 0 50%;
			margin-bottom: 12px;
		}
	}

}

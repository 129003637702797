//
// = Call To Action Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_download_area_v2 {
  text-align: center;
  padding: 0 0 100px;
  @include media-max(SM) {
  	padding: 0;
  }
  .st_download_content_wrapper {
		background-size: 100% 100%;
		background-repeat: no-repeat;
		h2 {
			margin: 0 0 25px;
		}
	}
}

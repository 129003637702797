//
// = Contact Block Styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_contact_area {
	.st_contact_hedding_title {
		padding: 0 0 40px;
		h2 {
			font-size: 1.5em;
			margin: 0 0 10px;
		}
		p {
			font-size: 0.875em;
		}
	}
}

.st_accordion_item{
	.card {
		position: relative;
		border: none;
		@extend %aqua-gredient-bgc;
			.card-header{
				padding: 25px 30px;
				margin-bottom: 0;
				background-color: $color-white;
				border-bottom: 1px solid rgba(30, 67, 221, 0.09);
				transition:all 0.4s;
				@include media-max(LG) {
					padding: 20px 20px;
				}
				h3{
					font-size: 1.125em;
					color: $color-haiti;
					margin: 0;
					font-weight: bold;
					@include media-max(LG) {
						@include font-size(15);
						float: left;
						width: calc(100% - (44px + 18px + 20px + 10px));
						white-space: normal;
						text-align: left;
						font-weight: normal;
						margin-right: 10px;
						margin-top: 7px;
					}
				}
				.btn-link{
					display: flex;
					align-items: center;
					@include media-max(LG) {
						display: block;
						overflow: hidden;
					}
				}
				.btn {
					padding: 0;
				}
			}
		}
		.st_accordion_icon_1 {
		  margin-right: 18px;
		  background-color: $color-white;
		  color: $color-perison-blue;
		  width: 40px;
		  height: 40px;
		  line-height: 40px;
		  text-align: center;
		  border-radius: 100%;
		  box-shadow: 0 6px 20px rgba(30, 67, 221, 0.13);
			@include media-max(LG) {
				float: left;
				box-shadow: none;
				border: 1px solid;
			}
		}
		.st_st_accordion_icon_2{
			font-size: .75em;
			color: $color-aqua;
			border: 2px solid $color-aqua;
			width: 20px;
			height: 20px;
			line-height: 18px;
			margin-left: auto;
			justify-self: flex-end;
			position: relative;
			border-radius: 100%;
			@include media-max(LG) {
				float: right;
				margin-top: 11px;
			}
		}
		.card.card__active{
			.card-header{
				border: none;
				background-color: transparent !important;
				h3{
					color: #fff;
				}
			}
			.st_st_accordion_icon_2{
				color: #fff;
				border: 2px solid #fff;
				&:before{
					content: "\f068";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					position: absolute;
					top: 0px;
					left: 3px;
				}
				i{
					display: none;	
				}
			}
		}
		.card-body {
			padding: 0px 25px 25px 92px;
			font-size: .875em;
			line-height: 1.7;
			color: $color-white;
			@include media-max(LG) {
				padding: 0px 25px 25px 25px;
			}
		}
}

.st_contact_waraper{
	background-color: #fff;
	padding: 70px 56px;
	box-shadow: 0 10px 50px rgba(0, 0, 0, 0.02);
	@include media-max(SM) {
		height: inherit !important;
		padding: 36px 30px;
	}
	.st_contact_form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include media-max(SM) {
			display: block;
			margin-bottom: 35px;
		}
	}
	.st_input_item {
		flex: 0 0 48%;
		margin: 0 0 35px;
		&:last-child{
			margin: 0;
		}
		input,textarea{
			background-color: #F5F9FD;
			width: 100%;
			border: none;
			border: 1px solid rgba(30, 67, 221, 0.09);
			padding: 14px 25px;
			@include font-size(14);
			border-radius: 3px;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.02);
		}
		textarea {
			height: 220px;
			resize: none;
		}
		input[type="submit"]{
			background-color: #1ED9F9;
			width: 100%;
			color: #fff;
			font-weight: 600;
			border: none;
			font-size: .813em;
			padding: 14px 25px;
			margin: 0;
			cursor: pointer;
			border-radius: 3px;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16);
			@include transition();
			&:hover{
				background-color: #333;
				color:#fff;
			}
		}
	}
}

input.filed_error {
    border-color: red !important;
}
.st-form-info {
    width: 100%;
    overflow: hidden;
	display: none;
	margin-top: 25px;
	text-align: center;
	.st-form-loader {
		-webkit-animation: 3s linear 0s normal none infinite running rotation;
		animation: 3s linear 0s normal none infinite running rotation;
		background-color: transparent;
		background-image: url("../images/loading.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% auto;
		display: block;
		height: 20px;
		position: relative;
		width: 20px;
		margin: 0 auto;
		-webkit-transition: all 0.4s ease 0s;
		transition: all 0.4s ease 0s;
	}
	.st-form-info-success {
		i {
			color: #2eb267;
		}
	}
	.st-form-info-error {
		i {
			color: #ff0000;
		}
	}
}
//
// = Image Mapping Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_image_mapping_wrap {
  position: relative;
  & > img {
    box-shadow: 0 40px 66px rgba(0,0,0,0.03);
  }
  &.st_image_mapping_wrap_v2 {
    width: 80%;
    margin: 0 auto;
    & > img {
      box-shadow: none;
    }
    @include media-max(MD) {
      width: 100%;
    }
  }
  @include media-max(SM) {
    .st_tooltip {
      display: none;
    }
  }
}
.st_tooltip {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 9px solid #F3F0FB;
  background-color: #8066DC;
  border-radius: 50%;
  cursor: help;
  span {
    position: absolute;
    display: inline-block;
    width: auto;
    background-color: #ECEAF6;
    white-space: nowrap;
    color: #140F30;
    line-height: 1;
    padding: 16px 24px;
    font-weight: bold;
    top: -45px;
    left: 50%;
    opacity: 0;
    @include transform(translateX(-50%) scale3d(0, 0, 0));
    @include transition();
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 14px solid #ECEAF6;
      position: absolute;
      bottom: -13px;
      left: 50%;
      @include transform(translateX(-50%));
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: #fff;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    width: 1px;
    height: 50%;
  }
  &::after {
    height: 1px;
    width: 50%;
  }
  &:hover {
    span {
      opacity: 1;
      @include transform(translateX(-50%) scale3d(1, 1, 1));
    }
  }
  &.st_tooltip_post_1 {
    top: 115px;
    right: 100px;
  }
  &.st_tooltip_post_2 {
    bottom: 245px;
    left: 165px;
  }
  &.st_tooltip_post_3 {
    bottom: 67px;
    left: 500px;
  }
  &.st_tooltip_post_4 {
    right: 23px;
    bottom: 50px;
  }
}

.st_image_mapping_wrap_v2 {
  .st_tooltip {
    &.st_tooltip_post_4 {
      right: 128px;
      bottom: 81px;
    }
    @include media-max(MD) {
      &.st_tooltip_post_3 {
        left: 250px;
      }
    }
  }
}

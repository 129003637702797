//
// = List Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_faq_item {
	margin-bottom: 50px;
	padding-right: 50px;
	.st_faq_single_item {
	  margin-bottom: 20px;
	  overflow: hidden;
	  i {
			font-size: 1.125em;
			margin-right: 15px;
	  }
	  h3 {
	  	display: inline-block;
	  	margin-bottom: 0;
	  }
	}
	.st-faq-content {
	  padding-left: 38px;
	  p {
	  	font-size: 0.9em;
	  	line-height: 1.85;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include media-max(SM) {
		padding-right: 0;
		.st_faq_single_item {
			i {
				width: 20px;
  				float: left;
  				margin-top: 7px;
			}
			h3 {
				display: block;
				float: left;
				width: calc(100% - (20px + 15px));
			}
		}
	}
}
.st_faq_area.st_section_padding {
  padding-bottom: 100px;
}




// Shorcode list
.st-shortcode-wrapper {
  position: relative;
  ul {
  	margin: 0;
	  padding: 0;
	  list-style: none;
	  border-right: 1px solid rgba(0,0,0,0.1);
	  border-bottom: 1px solid rgba(0,0,0,0.1);
	  li {
	  	width: 11.1111111111%;
		  float: left;
		  border-top: 1px solid rgba(0,0,0,0.1);
		  border-left: 1px solid rgba(0,0,0,0.1);
		  opacity: 0.8;
		  @include transition();
		  &:hover,
		  &:focus,
		  &:active {
		  	opacity: 1
		  }
		  a {
		  	display: block;
			  text-align: center;
			  padding: 25px 10px;
			  &:hover,
			  &:focus,
			  &:active {
			  	color: $color-science-blue;
			  	i,
			  	h2 {
			  		color: currentColor;
			  	}
			  }
			  img {
			  	display: inline-block;
				  width: 32px;
				  margin-bottom: 8px;
			  }
			  i {
					@include font-size(34);
					line-height: 1;
					color: currentColor;
			  }
			  h2 {
			  	@include font-size(12);
			  	margin-bottom: 0;
			  	color: currentColor;
					display: block;
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
			  }
		  }
		  @include media-max(MD) {
				width: 16.6666666667%;
		  }
		  @include media-max(XS) {
		  	width: 33.3333333333%;
		  }
	  }
		&.ls-column-8 {
			li {
				width: 12.5%;
			}
		}
		&.ls-column-7 {
			li {
				width: 14.2857142857%;
			}
		}
		&.ls-column-6 {
			li {
				width: 16.6666666667%;
			}
		}
		&.ls-column-5 {
			li {
				width: 20%;
			  @include media-max(XS) {
			  	width: 33.3333333333%;
			  }
			}
		}
		&.ls-column-4 {
			li {
				width: 25%;
				@include media-max(XS) {
			  	width: 33.3333333333%;
			  }
			}
		}
		&.ls-column-3 {
			li {
				width: 33.3333333333%;
			}
		}
		&.ls-column-2 {
			li {
				width: 50%;
			}
		}
		&.ls-column-1 {
			li {
				width: 100%;
			}
		}
  }
}
.st-shortcode-wrapper::before,
.st-shortcode-wrapper::after,
.st-shortcode-wrapper ul::before,
.st-shortcode-wrapper ul::after {
  content: "";
  display: table;
  clear: both;
}
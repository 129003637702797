//
// = Feature Element styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_team_item {
  text-align: center;
  @include media-max(MD) {
		margin-bottom: 50px;
	}
	@include media-max(SM) {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}
  .st_single_team_bg {
	  background-image: url(../images/team-mask-2.png);
	  background-size: 90% 90%;
	  background-repeat: no-repeat;
	  background-position: center center;
	  .st_team_single_img{
		  mask-image: url(../images/team-mask.png);
		  mask-repeat: no-repeat;
		  mask-size: 120% 120%;
		  mask-position: -20px -30px;
		  -webkit-mask-image: url(../images/team-mask.png);
		  -webkit-mask-repeat: no-repeat;
		  -webkit-mask-size: 120% 120%;
		  -webkit-mask-position: -20px -30px;
		  margin-bottom: 20px;
		  width: 100%;
		  height: auto;
		  img {
		  	@include media-max(MD) {
					display: block;
					width: 100%;
		  	}
		  }
		}
	}
  .st_team_content {
		h3{
			margin: 0 0 10px;
		}
		p{
			@include font-size(14);
			line-height: 1;
			margin: 0 0 25px;
		}
	}
	.st_team_social_icon {
		ul{
			@include st_ul();
			li{
				display: inline-block;
				margin: 0 8px;
				text-transform: capitalize;
				a{
					@include font-size(18);
					color: $color-primary;
					@include transition();
					&:hover{
						color: #333333;
					}
				}
			}
		}
	}
}


.st_team_item_v2{
	background-color: $color-white;
	box-shadow: 0 3px 36px rgba(0, 0, 0, 0.07);
	padding: 20px;
	border-bottom: 4px solid #E9ECFF;
	@include transition();
	&:hover{
		border-bottom: 4px solid $color-science-blue;
	}
	.st_team_member{
		text-align: center;
		@include media-max(XS) {
			img {
				display: block;
				width: 100%;
			}
		}
	}
	.st_team_content{
		display: flex;
		justify-content: space-between;
		padding: 31px 0 15px;
		.st_team_single_content{
			h3{
				margin-bottom: 8px;
			}
			p{
				@include font-size(14);
				margin-bottom: 0;
			}
		}
		@include media-max(LG) {
			display: block;
			.st_team_single_content{
				text-align: center;
			}
		}
	}
	.st_team_social_icon{
		display: flex;
		align-items: end;
		ul{
			@include st_ul();
			li{
				display: inline-block;
				margin: 0 2px;
				text-transform: capitalize;
				a{
					color: $color-primary;
					font-size: 1.125em;
					@include transition();
				}
			}
		}
		@include media-max(LG) {
			margin-top: 15px;
			display: block;
			text-align: center;
		}
	}
	&:hover .st_team_social_icon ul li a{
		color: $color-science-blue;
	}
}

.st_team_item {
	&.st_team_item_v3 {
		.st_team_item_img {
			width: 172px;
			height: 172px;
			overflow: hidden;
			margin: 0 auto;
			margin-bottom: 30px;
			border: 10px solid $color-white;
			box-shadow: 0 3px 80px rgba(0, 0, 0, 0.1);
			border-radius: 100%;
			img{
				@include transform(scale(1));
				@include transition();
				overflow: hidden;
				width: 100%;
				height: 100%;
				object-fit:cover;
				object-position: center;
				&:hover{
					@include transform(scale(1.2));
				}
			}
		}
		.st_team_content{
			h3{
				font-family: $font-body;
				font-weight: 500;
				margin-bottom: 0;
			}
			p{
				@include font-size(14);
				margin-bottom: 10px;
			}
		}
	}
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
    .st_team_item {
        -webkit-transform: translate3d(0,0,0);
    }
}

.st_team_area {
  padding: 100px 0 150px;
  @include media-max(MD) {
    padding: 100px 0 100px;
  }
  @include media-max(SM) {
    padding: 80px 0 40px;
  }
}



// st team 4
.st_team_4 {
	.st_team_single_content {
		border-left: 4px solid #ECEAF6;
		h3 {
			font-family: $font-body;
		}
	}
	.st_team_hover_content {
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		position: absolute;
		@include transform(translateX(-25%));
		@include transition();
		padding: 40px 10px 35px 25px;
		background-color: rgba(245, 249, 253, 0.95);
		h3 {
			font-family: $font-body;
			@include font-size(18);
			font-weight: 500;
			margin-bottom: 0.9375rem;
			line-height: 1;
		}
		p {
			margin-bottom: 1.5625rem;
			color: $color-haiti;
			@include font-size(14);
			@include media-max(LG) {
				margin-bottom: 0.9375rem;
			}
			@include media-max(SM) {
				margin-bottom: 0.625rem;
			}
		}
	}
	.st_team_member {
		overflow: hidden;
		height: 360px;
		@include media-max(LG) {
			height: 300px;
		}
		@include media-max(MD) {
			height: 330px;
		}
		&:hover {
			.st_team_hover_content{
				opacity: 1;
				height: 100%;
				transform: translateX(0);
			}
		}
	}
} 
